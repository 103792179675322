import React, { useEffect, useState } from "react";
import { UseQuestionPaperContext } from "../ContextApis/QuestionPaper";
import { StaggeredGrid, StaggeredGridItem } from "react-staggered-grid";
import { BackArrowIcon, DetailIcon } from "../common/Icons";
import { Link } from "react-router-dom";
import { getToken } from "../ContextApis/TokenStore";
export default function AllExams() {
  const { AllExam } = UseQuestionPaperContext();
  const [filtredExam, setFilterdExam] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [username, setUsername] = useState("");

  useEffect(() => {
    let user = sessionStorage.getItem("userName") || localStorage.getItem("userName");
    setUsername(user);
  }, []);

  useEffect(() => {
    let filterExamname = JSON.parse(localStorage.getItem("exam")).Exam;
    for (let i of AllExam) {
      for (let a in i.contents.items) {
        console.log(i.contents.items[a].name);
        if (i.contents.items[a].name === filterExamname) {
          setFilterdExam(i.contents.items[a]);
        }
      }
    }
  }, [AllExam]);
  return (
    <>
      {!Array.isArray(filtredExam) && (
        <section className="px-6 max-sm:px-4">
          <div className="container mx-auto mt-4 sm:my-10 lg:mb-10 sm:mb-12 mb-4">
            <div className="flex items-center justify-center sm:mt-10 mb-4">
              <button className="px-3" onClick={() => window.history.back()}>
                <BackArrowIcon />
              </button>
              <p className="text-center text-[20px] max-md:text-[20px] max-sm:text-[16px] font-semibold">
                {filtredExam.name.split("_").join(" ")}
              </p>
            </div>
            {filtredExam.contents.items[0].name.includes("$") ? (
              <StaggeredGrid
                columns={windowWidth >= 1536 ? 3 : windowWidth > 640 ? 2 : 1}
                alignment={1}
                horizontalGap={
                  windowWidth > 1024 ? 80 : windowWidth > 768 ? 50 : windowWidth > 624 ? 40 : 20
                }
                verticalGap={windowWidth > 768 ? 40 : 20}
                fitHorizontalGap={true}>
                {filtredExam.contents.items.map((item, ind) => {
                  return (
                    <StaggeredGridItem key={ind} index={ind}>
                      <Link
                        target="_blank"
                        onClick={() => {
                          let token = getToken();
                          if (token === null || username === "Guest") {
                            localStorage.setItem("sharePath", `/showpdf2/${item.name}=${item.id}`);
                          }
                        }}
                        to={`/showpdf2/${item.name}=${item.id}`}
                        className="flex items-center justify-between flex-col ">
                        <div
                          style={{ boxShadow: "0px 8px 20px 0px #2F327D1A" }}
                          className="flex items-center justify-between w-full px-6 md:px-[30px] xl:px-[40px] xxl:px-[54px] max-md:rounded-[6px] rounded-[16px] h-[80px] md:h-[100px] lg:h-[160px]">
                          <div className="overflow-hidden">
                            <p className="text-xl max-xl:text-[20px] max-lg:text-[18px] max-md:text-[14px] lg:font-medium question_paper_name relative">
                              {item.name?.split("$$")[1]?.split(".")[0]?.split("_")[0]}{" "}
                              {item.name?.split("$$")[1]?.split(".")[0]?.split("_")[1]}
                            </p>
                          </div>
                          <button className="add_qus_btn ms-2">
                            <DetailIcon />
                          </button>
                        </div>
                      </Link>
                    </StaggeredGridItem>
                  );
                })}
              </StaggeredGrid>
            ) : (
              filtredExam.contents.items.map((item, ind) => {
                return (
                  <div key={ind}>
                    {!item.name.includes("$") && (
                      <p className="text-center text-[20px] max-md:text-[20px] max-sm:text-[16px] font-semibold mb-4">
                        {item.name}
                      </p>
                    )}
                    <div>
                      {item.contents && item.contents.items[0].name.includes("$") ? (
                        <StaggeredGrid
                          columns={windowWidth >= 1536 ? 3 : windowWidth > 640 ? 2 : 1}
                          alignment={1}
                          horizontalGap={
                            windowWidth > 1024
                              ? 80
                              : windowWidth > 768
                              ? 50
                              : windowWidth > 624
                              ? 40
                              : 20
                          }
                          verticalGap={windowWidth > 768 ? 40 : 20}
                          fitHorizontalGap={true}>
                          {item.contents.items.map((items, index) => {
                            return (
                              <StaggeredGridItem key={index} index={index}>
                                <div className="flex items-center justify-between flex-col ">
                                  <Link
                                    target="_blank"
                                    onClick={() => {
                                      let token = getToken();
                                      if (token === null || username === "Guest") {
                                        localStorage.setItem(
                                          "sharePath",
                                          `/showpdf2/${items.name}=${items.id}`
                                        );
                                      }
                                    }}
                                    to={`/showpdf2/${items.name}=${items.id}`}
                                    style={{ boxShadow: "0px 8px 20px 0px #2F327D1A" }}
                                    className="flex items-center justify-between w-full px-6 md:px-[30px] xl:px-[40px] xxl:px-[54px] max-md:rounded-[6px] rounded-[16px] h-[80px] md:h-[100px] lg:h-[160px]">
                                    <div className="overflow-hidden">
                                      <p className="text-xl max-xl:text-[20px] max-lg:text-[18px] max-md:text-[14px] lg:font-medium question_paper_name relative">
                                        {items.name?.split("$$")[1]?.split(".")[0]?.split("_")[0]}{" "}
                                        {items.name?.split("$$")[1]?.split(".")[0]?.split("_")[1]}
                                      </p>
                                    </div>
                                    <button className="add_qus_btn ms-2">
                                      <DetailIcon />
                                    </button>
                                  </Link>
                                </div>
                              </StaggeredGridItem>
                            );
                          })}
                        </StaggeredGrid>
                      ) : (
                        item.contents &&
                        item.contents.items.slice(0, 4).map((items, index4) => {
                          return (
                            <div key={index4}>
                              <p className="text-center text-[20px] max-md:text-[20px] max-sm:text-[16px] font-semibold mb-4">
                                {items.name}
                              </p>
                              {items.contents && items.contents.items[0].name.includes("$") ? (
                                <StaggeredGrid
                                  columns={windowWidth >= 1536 ? 3 : windowWidth > 640 ? 2 : 1}
                                  alignment={1}
                                  horizontalGap={
                                    windowWidth > 1024
                                      ? 80
                                      : windowWidth > 768
                                      ? 50
                                      : windowWidth > 624
                                      ? 40
                                      : 20
                                  }
                                  verticalGap={windowWidth > 768 ? 40 : 20}
                                  fitHorizontalGap={true}>
                                  {items.contents.items.map((items5, index2) => {
                                    return (
                                      <StaggeredGridItem key={index2} index={index2}>
                                        <div className="flex items-center justify-between flex-col ">
                                          <Link
                                            target="_blank"
                                            onClick={() => {
                                              let token = getToken();
                                              if (token === null || username === "Guest") {
                                                localStorage.setItem(
                                                  "sharePath",
                                                  `/showpdf2/${items5.name}=${items5.id}`
                                                );
                                              }
                                            }}
                                            to={`/showpdf2/${items5.name}=${items5.id}`}
                                            style={{ boxShadow: "0px 8px 20px 0px #2F327D1A" }}
                                            className="flex items-center justify-between w-full px-6 md:px-[30px] xl:px-[40px] xxl:px-[54px] max-md:rounded-[6px] rounded-[16px] h-[80px] md:h-[100px] lg:h-[160px]">
                                            <div className="overflow-hidden">
                                              <p className="text-xl max-xl:text-[20px] max-lg:text-[18px] max-md:text-[14px] lg:font-medium question_paper_name relative">
                                                {
                                                  items5.name
                                                    ?.split("$$")[1]
                                                    ?.split(".")[0]
                                                    ?.split("_")[0]
                                                }{" "}
                                                {
                                                  items5.name
                                                    ?.split("$$")[1]
                                                    ?.split(".")[0]
                                                    ?.split("_")[1]
                                                }
                                              </p>
                                            </div>
                                            <button className="add_qus_btn ms-2">
                                              <DetailIcon />
                                            </button>
                                          </Link>
                                        </div>
                                      </StaggeredGridItem>
                                    );
                                  })}
                                </StaggeredGrid>
                              ) : (
                                items.contents &&
                                items.contents.items((itemss, index3) => {
                                  return (
                                    <div key={index3}>
                                      <p className="text-center text-[20px] max-md:text-[20px] max-sm:text-[16px] font-semibold mb-4">
                                        {itemss.name}
                                      </p>
                                      {itemss.contents &&
                                      itemss.contents.items[0].name.includes("$") ? (
                                        <StaggeredGrid
                                          columns={
                                            windowWidth >= 1536 ? 3 : windowWidth > 640 ? 2 : 1
                                          }
                                          alignment={1}
                                          horizontalGap={
                                            windowWidth > 1024
                                              ? 80
                                              : windowWidth > 768
                                              ? 50
                                              : windowWidth > 624
                                              ? 40
                                              : 20
                                          }
                                          verticalGap={windowWidth > 768 ? 40 : 20}
                                          fitHorizontalGap={true}>
                                          {itemss.contents.items.map((items8, index8) => {
                                            return (
                                              <StaggeredGridItem key={index8} index={index8}>
                                                <div className="flex items-center justify-between flex-col ">
                                                  <Link
                                                    target="_blank"
                                                    onClick={() => {
                                                      let token = getToken();
                                                      if (token === null || username === "Guest") {
                                                        localStorage.setItem(
                                                          "sharePath",
                                                          `/showpdf2/${items8.name}=${items8.id}`
                                                        );
                                                      }
                                                    }}
                                                    to={`/showpdf2/${items8.name}=${items8.id}`}
                                                    style={{
                                                      boxShadow: "0px 8px 20px 0px #2F327D1A",
                                                    }}
                                                    className="flex items-center justify-between w-full px-6 md:px-[30px] xl:px-[40px] xxl:px-[54px] max-md:rounded-[6px] rounded-[16px] h-[80px] md:h-[100px] lg:h-[160px]">
                                                    <div className="overflow-hidden">
                                                      <p className="text-xl max-xl:text-[20px] max-lg:text-[18px] max-md:text-[14px] lg:font-medium question_paper_name relative">
                                                        {
                                                          items8.name
                                                            ?.split("$$")[1]
                                                            ?.split(".")[0]
                                                            ?.split("_")[0]
                                                        }{" "}
                                                        {
                                                          items8.name
                                                            ?.split("$$")[1]
                                                            ?.split(".")[0]
                                                            ?.split("_")[1]
                                                        }
                                                      </p>
                                                    </div>
                                                    <button className="add_qus_btn ms-2">
                                                      <DetailIcon />
                                                    </button>
                                                  </Link>
                                                </div>
                                              </StaggeredGridItem>
                                            );
                                          })}
                                        </StaggeredGrid>
                                      ) : (
                                        <div>
                                          <p></p>
                                        </div>
                                      )}
                                    </div>
                                  );
                                })
                              )}
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </section>
      )}
    </>
  );
}
