// import React, { useEffect, useState } from "react";
// import Spotlight from "../../components/Home/Spotlight";
// import { LeftIcon } from "../../components/common/Icons";
// import { UseHomeContext } from "../ContextApis/HomeContext";
// import { UseInterestContext } from "../ContextApis/SettingsContext";
// const AllSpotlight = () => {
//   const { Spotlightdata, GetSpotlightdata } = UseHomeContext();
//   const { getInterestData } = UseInterestContext();
//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 9; // Display 9 items per page
//   function scrollToTop() {
//     window.scrollTo({ top: 0, behavior: "smooth" });
//   }
//   // Calculate the total number of pages
//   const totalPages = Math.ceil(Spotlightdata.length / itemsPerPage);

//   // Get the data for the current page
//   const currentItems = Spotlightdata.slice(
//     (currentPage - 1) * itemsPerPage,
//     currentPage * itemsPerPage
//   );

//   // Handle previous page
//   const handlePrevPage = () => {
//     if (currentPage > 1) {
//       setCurrentPage((prev) => prev - 1);
//       scrollToTop();
//     }
//   };

//   // Handle next page
//   const handleNextPage = () => {
//     if (currentPage < totalPages) {
//       setCurrentPage((prev) => prev + 1);
//       scrollToTop();
//     }
//   };

//   async function callApis() {
//     let storeTopic = sessionStorage.getItem("topic");
//     let insData = await getInterestData();
//     GetSpotlightdata(
//       storeTopic !== null
//         ? storeTopic
//         : typeof insData === "object" && Object.values(insData).length !== 0
//         ? ""
//         : ""
//     );
//   }
//   useEffect(() => {
//     callApis();
//   }, []);
//   return (
//     <>
//       <section className="px-6 max-sm:px-4">
//         <div className="md:pt-[40px] sm:pt-[50px] flex items-center sm:justify-center justify-start md:pb-[40px] sm:pb-[50px] pb-4">
//           <button onClick={() => window.history.back()}>
//             <LeftIcon />
//           </button>
//           <span className=" text-black font-semibold sm:text-lg text-base text-center ">
//             Spotlight
//           </span>
//         </div>
//         <div className="container mx-auto grid grid-rows-1 sm:grid-cols-3 grid-cols-2 xl:gap-x-[77px] sm:gap-x-[42px] gap-x-4 lg:mb-10 sm:mb-12 mb-4 gap-y-7 max-sm:gap-y-4">
//           {currentItems.map((item, index) => {
//             return (
//               <div key={index}>
//                 <Spotlight data={item} />
//               </div>
//             );
//           })}
//         </div>
//         <div className="flex justify-center items-center gap-4 mt-6 md:mb-4">
//           <button
//             onClick={handlePrevPage}
//             disabled={currentPage === 1}
//             className="px-4 py-1 md:py-2 bg-[#4d8aff] text-white rounded disabled:opacity-50 max-md:text-sm">
//             Prev
//           </button>
//           <p className="max-md:text-sm">
//             Page {currentPage} of {totalPages}
//           </p>
//           <button
//             onClick={handleNextPage}
//             disabled={currentPage === totalPages}
//             className="px-4 py-1 md:py-2 bg-[#4d8aff] text-white rounded  disabled:opacity-50 max-md:text-sm">
//             Next
//           </button>
//         </div>
//       </section>
//     </>
//   );
// };
// export default AllSpotlight;



import React, { useEffect, useState } from "react";
import Spotlight from "../../components/Home/Spotlight";
import { LeftIcon } from "../../components/common/Icons";
import { UseHomeContext } from "../ContextApis/HomeContext";
import { UseInterestContext } from "../ContextApis/SettingsContext";

import bansal_sndp from "../../assets/images/jpeg/dummyimage.jpg";
import { useParams } from "react-router-dom";
import { UseProfileContext } from "../ContextApis/ProfileContext";
const AllSpotlight = () => {
  const { Spotlightdata, GetSpotlightdata } = UseHomeContext();
  const { getInterestData } = UseInterestContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [userData, setUserData] = useState({});
  const { profileData, getOtherUserProfileData } = UseProfileContext();

  const { username } = useParams();
  async function getUserProfileData() {
    const otherUserData = await getOtherUserProfileData(username);
    setUserData(otherUserData);
  }

  useEffect(() => {
    if (username !== undefined) {
      getUserProfileData();
    }
    if (username === undefined) {
      setUserData(profileData);
    }
  }, [username || profileData]);

  console.log("userData all", userData);

  const itemsPerPage = 9; // Display 9 items per page
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  // Calculate the total number of pages
  const totalPages = Math.ceil(Spotlightdata.length / itemsPerPage);

  // Get the data for the current page
  const currentItems = Spotlightdata.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle previous page
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
      scrollToTop();
    }
  };

  // Handle next page
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
      scrollToTop();
    }
  };

  async function callApis() {
    let storeTopic = sessionStorage.getItem("topic");
    let insData = await getInterestData();
    GetSpotlightdata(
      storeTopic !== null
        ? storeTopic
        : typeof insData === "object" && Object.values(insData).length !== 0
        ? ""
        : ""
    );
  }
  useEffect(() => {
    callApis();
  }, []);
  return (
    <>
      <section className="px-6 max-sm:px-4">
        <div className="md:pt-[40px] sm:pt-[50px] flex items-center sm:justify-center justify-start md:pb-[40px] sm:pb-[50px] pb-4">
          <button onClick={() => window.history.back()}>
            <LeftIcon />
          </button>
          <span className=" text-black font-semibold sm:text-lg text-base text-center ">
            Spotlight
          </span>
        </div>
        <div className="container mx-auto xl:gap-x-[77px] sm:gap-x-[42px] gap-x-4 lg:mb-10 sm:mb-12 mb-4 gap-y-7 max-sm:gap-y-4">
          <div className="flex flex-wrap justify-between">
            <div className="w-1/4 md:block hidden">
              <div className="flex flex-col justify-center gap-1 border bg-white shadow-2xl p-5 overflow-hidden rounded-[15px] sticky top-[100px]">
                <div
                  className={`absolute top-0 left-0 w-full h-1/3 bg-slate-500 z-0`}
                >
                  {userData?.coverImageUrl ? (
                    <img
                      src={userData.coverImageUrl}
                      alt="Cover-Image"
                      className="w-full h-full object-cover"
                    />
                  ) : null}
                </div>
                {userData && userData && (
                  <div className="border flex items-center  w-[72px] h-[72px]  justify-center object-cover border-[#dcdbdb]  rounded-full mt-4 z-1 relative">
                    <img
                      src={userData.profileImageUrl || bansal_sndp}
                      alt="img"
                      className=" object-cover object-top  w-full h-full rounded-full"
                    />
                  </div>
                )}
                <h2 className="font-medium text-base lg:text-lg capitalize text-black">
                  {" "}
                  {userData?.userName || "Guest User"}
                </h2>
                <h3 className="font-normal text-sm capitalize text-black">
                  {" "}
                  {userData?.bio || ""}
                </h3>
                <h3 className="font-normal text-sm capitalize text-black">
                  {userData?.designation || ""}
                </h3>
                <h4 className="font-normal text-sm capitalize text-black/60">
                  {userData?.city || ""}
                </h4>
              </div>
            </div>
            <div className="w-full md:w-8/12 mx-auto">
              {currentItems.map((item, index) => {
                return (
                  <div key={index} className={` ${index === 0 ? "" : "mt-5"}`}>
                    <Spotlight data={item} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center gap-4 mt-6 md:mb-4">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className="px-4 py-1 md:py-2 bg-[#4d8aff] text-white rounded disabled:opacity-50 max-md:text-sm"
          >
            Prev
          </button>
          <p className="max-md:text-sm">
            Page {currentPage} of {totalPages}
          </p>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="px-4 py-1 md:py-2 bg-[#4d8aff] text-white rounded  disabled:opacity-50 max-md:text-sm"
          >
            Next
          </button>
        </div>
      </section>
    </>
  );
};
export default AllSpotlight;
