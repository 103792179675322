import React from "react";
import ReactDOM from "react-dom/client";
import "./App.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./components/ContextApis/AuthContext";
import { ProfileProvider } from "./components/ContextApis/ProfileContext";
import { InterestProvider } from "./components/ContextApis/SettingsContext";
import { HomeProvider } from "./components/ContextApis/HomeContext";
import { CreatePostContextProvider } from "./components/ContextApis/CreatePostContext";
import { NotificationsProvider } from "./components/ContextApis/NotificationsContext";
import { NavDataProvider } from "./components/ContextApis/NavDataContext";
import { TopCreatorsProvider } from "./components/ContextApis/TopCreatorsAndFollow";
import { QuestionPaperContextProvider } from "./components/ContextApis/QuestionPaper";
import { PDFContextProvider } from "./components/ContextApis/PDF";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <BrowserRouter>
      <AuthProvider>
        <HomeProvider>
          <CreatePostContextProvider>
            <ProfileProvider>
              <InterestProvider>
                <NotificationsProvider>
                  <NavDataProvider>
                    <QuestionPaperContextProvider>
                      <TopCreatorsProvider>
                        <PDFContextProvider>
                          <App />
                        </PDFContextProvider>
                      </TopCreatorsProvider>
                    </QuestionPaperContextProvider>
                  </NavDataProvider>
                </NotificationsProvider>
              </InterestProvider>
            </ProfileProvider>
          </CreatePostContextProvider>
        </HomeProvider>
      </AuthProvider>
    </BrowserRouter>
  </>
);
