import React, { useEffect, useRef, useState } from "react";
import logo from "../../assets/images/png/Logo.png";
import Nav_profile_img from "../../assets/images/png/nav_profile_img.png";
import search_icon_mobile_view from "../../assets/images/svg/search_icon_mobile_view.svg";
import hamburger_profile_icon from "../../assets/images/svg/profile3.svg";
import backward_arrow from "../../assets/images/svg/backward_arrow.svg";
import {
  CloseIcon,
  Create_icon,
  Home_icon,
  Notification_icon,
  Search_icon,
  Setting_icon,
  ShieldIcon,
  Stream_icon,
} from "./Icons";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Notification from "../popup/Notification";
import Search from "../popup/Search";
import CreatePost from "../Create/CreatePost";
import { toast } from "react-toastify";
import { UseNotificationsContext } from "../ContextApis/NotificationsContext";
import { UseProfileContext } from "../ContextApis/ProfileContext";
import { getToken } from "../ContextApis/TokenStore";

const NavBar = () => {
  
  // State variables
  // const [isSearching, setIsSearching] = useState(false);
  const [settingsPopup, setSettingsPopup] = useState(false);
  const [searchpopup, setSearchpopup] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [notificationCount, setnotificationCount] = useState(0);
  const { notification } = UseNotificationsContext();
  const { profileData } = UseProfileContext();
  useEffect(() => {
    let user = sessionStorage.getItem("userName");
    setUsername(user);
  });
  useEffect(() => {
    if (Array.isArray(notification) && notification.length > 0) {
      let count = notification.filter((item) => item.notificationStatus == "UNREAD");
      if (count.length > 0) {
        setnotificationCount(count.length);
      } else {
        setnotificationCount(0);
      }
    }
  }, [notification]);
  // Refs

  const popupRef = useRef(null);
  // Close popup when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        // setIsPopupVisible(false);
      }
    }
    if (settingsPopup || searchpopup || isPopupVisible) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    if (isPopupVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopupVisible, settingsPopup, searchpopup]);
  const navigate = useNavigate();
  const notifications = async (event) => {
    event.preventDefault();
    if (username !== "Guest") {
      setSettingsPopup(true);
    } else {
      navigate("/login");
      sessionStorage.clear();
      localStorage.clear();
      toast.info("Please log in to access this feature.");
    }
  };
  const handleNavigation = (event) => {
    event.preventDefault();
    if (username === "Guest") {
      toast.info("Please log in to access this feature.");
      navigate("/login");
      sessionStorage.clear();
      localStorage.clear();
    } else {
      navigate("/settings/accounts-settings");
    }
  };
  const handleprofile = (event) => {
    event.preventDefault();
    if (username === "Guest") {
      toast.info("Please log in to access this feature.");
      navigate("/login");
      sessionStorage.clear();
      localStorage.clear();
    } else {
      navigate("/profile");
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  // Location hook
  const location = useLocation();

  return (
    <div>
      {(isPopupVisible || isOpen) && (
        <div
          className="backdrop-blur-[10px] z-[50] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] h-[100vh] body_overlay w-full max-sm:overflow-auto"
          onClick={toggleMenu}></div>
      )}
      {isPopupVisible && (
        <div ref={popupRef}>
          <CreatePost value={setIsPopupVisible} popupVisible={isPopupVisible} />
        </div>
      )}
      {(settingsPopup || searchpopup) && (
        <div
          onClick={() => {
            setSearchpopup(false);
            setSettingsPopup(false);
          }}
          className="backdrop-blur-[10px] z-50 fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] h-[100vh] w-full"></div>
      )}
      {settingsPopup && <Notification className="z-40" setSettingsPopup={setSettingsPopup} />}
      {searchpopup && (
        <>
          <div className="absolute top-3 right-[14px] cursor-pointer">
            <CloseIcon />
            <Search className="z-[100]" closeMenu={setSearchpopup} isCose={searchpopup} />
          </div>
        </>
      )}
      <div className="bg-white">
        <div className="bg-light-400 px-7">
          <nav className="w-full mx-auto lg:flex bg-light-400  lg:pt-[6px] lg:justify-between lg:container">
            <div className="lg:flex hidden  items-center gap-10 pb-1.5">
              <Link to="/home" className="w-[80px]">
                <img src={logo} alt="" />
              </Link>
              <div
                onClick={() => setSearchpopup(true)}
                className="w-[260px] xl:w-[360px] h-[34px] flex bg-white justify-between rounded-[6px] ps-5 items-center pe-5">
                <input
                  type="text"
                  name=""
                  id="input"
                  placeholder="Search"
                  className="focus:outline-none w-full"
                  readOnly
                />
                <label htmlFor="input">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M16.9407 15.446L16.9406 15.4459L12.79 11.2951C13.7344 9.98839 14.1726 8.38043 14.0196 6.77051C13.8595 5.08714 13.0642 3.52796 11.7955 2.41016C10.5267 1.29236 8.87973 0.699905 7.18965 0.753322C5.49957 0.806738 3.89331 1.50201 2.69765 2.6977C1.50199 3.8934 0.806736 5.49969 0.753322 7.1898C0.699907 8.87992 1.29235 10.5269 2.41012 11.7957C3.52789 13.0645 5.08703 13.8598 6.77037 14.0199C8.38033 14.1729 9.98822 13.7339 11.2949 12.7895L15.4455 16.9402C15.4455 16.9402 15.4455 16.9402 15.4455 16.9402C15.6437 17.1385 15.9124 17.2499 16.1927 17.25C16.473 17.2501 16.7419 17.1388 16.9402 16.9407C17.1385 16.7425 17.2499 16.4738 17.25 16.1934C17.2501 15.9131 17.1388 15.6442 16.9407 15.446ZM10.6175 10.6169C9.76422 11.4702 8.60697 11.9496 7.4003 11.9496C6.19362 11.9496 5.03637 11.4702 4.18312 10.6169C3.32987 9.76365 2.85052 8.60637 2.85052 7.39966C2.85052 6.19295 3.32987 5.03566 4.18312 4.18239C5.03637 3.32912 6.19362 2.84976 7.4003 2.84976C8.60697 2.84976 9.76422 3.32912 10.6175 4.18239C11.4707 5.03566 11.9501 6.19295 11.9501 7.39966C11.9501 8.60637 11.4707 9.76365 10.6175 10.6169Z"
                      fill="#BEC1C3"
                      stroke="#BEC1C3"
                      strokeWidth="0.5"
                    />
                  </svg>
                </label>
              </div>
            </div>

            <div className="navbar_link_size flex xl:space-x-4 justify-between px-5 lg:pe-0 max-sm:justify-between max-lg:justify-evenly max-lg:items-end  lg:items-end  lg:gap-1 mobile_navbar_bg z-40 max-lg:fixed max-lg:w-full left-0 bottom-0 max-lg:shadow-[0_0px_10px_0_rgba(0,0,0,0.3)]">
              <NavLink
                to="/home"
                className={`nav_links ${location.pathname.startsWith("/home") ? "active" : null}`}>
                <div className="flex lg:px-4 max-lg:py-[6.4px] flex-col items-center lg:border-b-[2px] border-transparent">
                  <Home_icon />
                  <p className=" text-xxs  lg:text-xs lg:mt-0.5">Home</p>
                </div>
              </NavLink>

              <NavLink
                to="/streamhome"
                className={`nav_links storke_link ${
                  location.pathname === "/streamhome" ||
                  location.pathname === "/streamrecommondations" ||
                  location.pathname === "/streamnotespage" ||
                  location.pathname === "/streamwriteups" ||
                  location.pathname === "/streamrecommendedpeople" ||
                  location.pathname === "/questionpaper"
                    ? "active"
                    : null
                }`}>
                <div className="flex lg:px-4 max-lg:py-[6.4px] flex-col  items-center lg:border-b-[2px] border-transparent">
                  <Stream_icon />
                  <p className=" text-xxs  lg:text-xs  lg:mt-0.5  whitespace-nowrap">By stream</p>
                </div>
              </NavLink>

              {username !== "Guest" && (
                <NavLink onClick={() => setIsPopupVisible(true)}>
                  <div className="flex lg:px-4 max-lg:py-[6.4px] flex-col  items-center lg:border-b-[2px] border-transparent">
                    <Create_icon />
                    <p className=" text-xxs  lg:text-xs lg:mt-0.5  ">Create</p>
                  </div>
                </NavLink>
              )}
              <a
                href="#"
                onClick={(e) => {
                  handleNavigation(e);
                  let token = getToken();
                  if (token === null || username === "Guest") {
                    sessionStorage.setItem("sharePath", "/settings");
                  }
                }}
                className={`nav_links  d_none ${
                  location.pathname.startsWith("/settings") ? "active" : null
                }`}>
                <div className=" lg:px-4 max-lg:py-[6.4px] flex-col  items-center lg:border-b-[2px] border-transparent  lg:flex">
                  <Setting_icon />
                  <p className=" text-xxs lg:text-xs lg:mt-0.5">Settings</p>
                </div>
              </a>
              <NavLink>
                <div
                  onClick={notifications}
                  className="flex lg:px-4 max-lg:py-[6.4px] flex-col  items-center lg:border-b-[2px] border-transparent ">
                  <div className="relative">
                    <Notification_icon />
                    {notificationCount > 0 && (
                      <p className="text-[9px] lg:text-xs absolute w-[17px] h-[14px]  lg:w-[20px] lg:h-[17px] lg:rounded-[8px] rounded-full bg-[#cb112d] lg:right-[-10px] -right-2.5 bottom-1.5 lg:bottom-[6px] flex items-center justify-center text-white">
                        {notificationCount}
                      </p>
                    )}
                  </div>
                  <p className=" text-xxs  lg:text-xs  lg:mt-0.5  ">Notifications</p>
                </div>
              </NavLink>
              {/* <NavLink
              to="/profile" */}
              <a
                href="#"
                onClick={(e) => {
                  handleprofile(e);
                  let token = getToken();
                  if (token === null || username === "Guest") {
                    sessionStorage.setItem("sharePath", "/profile");
                  }
                }}
                className={`nav_links hidden lg:block ${
                  location.pathname.startsWith("/profile") ? "active" : null
                }`}>
                <img
                  className="min-w-[40px] max-w-[40px] h-[40px] rounded-full d_none mb-1.5"
                  src={profileData?.profileImageUrl || Nav_profile_img}
                  alt=""
                />
                {/* </NavLink> */}
              </a>
            </div>

            {isOpen && (
              <div className=" flex lg:hidden max-lg:flex-col max-lg:justify-between max-lg:items-center lg:items-end z_51 gap-6 lg:gap-4 max-lg:fixed max-lg:bg-white nav_mobile_height  min-h-screen w-[60%] left-0  top-[0px] ">
                <img
                  onClick={toggleMenu}
                  className="absolute top-[30px] right-[30px]"
                  src={backward_arrow}
                  alt=""
                />
                <div className="flex items-center flex-col mt-[60px]">
                  <img
                    className="w-[68px] h-[68px] rounded-full"
                    src={profileData?.profileImageUrl || hamburger_profile_icon}
                    alt="hamburger_profile_icon"
                  />
                  <p className="text-base font-semibold mt-4 mb-0.5">{profileData?.fullName}</p>
                  <p className="text-base max-sm:text-sm px-3 text-center">
                    {profileData?.designation}
                  </p>
                </div>
                <div>
                  {username !== "Guest" && (
                    <NavLink
                      onClick={() => {
                        setIsOpen(false);
                        toggleMenu();
                      }}
                      to="/profile"
                      className="flex items-center py-[19.42px]">
                      <img
                        className="max-lg:w-[23.92px]"
                        src={hamburger_profile_icon}
                        alt="hamburger_profile_icon"
                      />
                      <p className="text-sm ms-1.5 ps-px">Profile</p>
                    </NavLink>
                  )}
                  <NavLink
                    onClick={() => {
                      setIsOpen(false);
                      toggleMenu();
                    }}
                    to="/settings"
                    className="flex items-center py-[19.42px]">
                    <Setting_icon />
                    <p className="text-sm ms-1.5 ps-px">Settings</p>
                  </NavLink>
                  <NavLink
                    onClick={() => {
                      setIsOpen(false);
                      toggleMenu();
                    }}
                    to="/settings/helps"
                    className="flex items-center py-[19.42px]">
                    <ShieldIcon />
                    <p className="text-sm ms-1.5 ps-px">Help</p>
                  </NavLink>
                </div>
                <div className="self-start ps-8 mb-3">
                  <p className="text-xxs"> ©2024 by Wised Inc.</p>
                  <p className="text-xxs">All Rights Reserved by Wised Inc.</p>
                </div>
              </div>
            )}
          </nav>
        </div>
        <nav className="container mx-auto sm:py-6 h-[60px] lg:hidden flex items-center justify-between bg-white sm:px-4 lg:px-0 px max-sm:px-[16px]">
          <div
            className="hamburger_menu w-[12px] h-[10px] flex flex-col justify-between "
            onClick={toggleMenu}>
            <span className="inline-block w-full bg-black h-[2px] rounded-sm"></span>
            <span className="inline-block w-full bg-black h-[2px] rounded-sm"></span>
            <span className="inline-block w-full bg-black h-[2px] rounded-sm"></span>
          </div>
          <Link to="/home">
            <img className="w-[46px] transition-all  sm:w-[68px]" src={logo} alt="logo" />
          </Link>

          <div className="flex">
            <button onClick={() => setSearchpopup(true)}>
              <label htmlFor="main_search">
                <img
                  className="w-[20px] h-[20px]"
                  src={search_icon_mobile_view}
                  alt="search_icon_mobile_view"
                />
              </label>
            </button>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default NavBar;
