import React, { useEffect, useState } from "react";
import { UseInterestContext } from "../ContextApis/SettingsContext";
import { Link, useParams } from "react-router-dom";
import * as pdfjsLib from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.entry";

export default function SimilarPosts({ item }) {
  const [pdf, setPdf] = useState(null);
  const [images, setImages] = useState([]);
  const [pageRendering, setPageRendering] = useState(false);

  const [fileType, setfileType] = useState("");

  async function loadPdf(pdfLink) {
    try {
      setPageRendering(true);
      const _PDF_DOC = await pdfjsLib.getDocument({ url: pdfLink }).promise;
      setPdf(_PDF_DOC);
      setPageRendering(false);
    } catch (error) {
      console.error("Error loading PDF: ", error.message);
    }
  }
  async function renderPage() {
    if (!pdf) return;
    setPageRendering(true);
    const imagesList = [];
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const viewport = page.getViewport({ scale: 1 });
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      await page.render({ canvasContext: context, viewport }).promise;
      imagesList.push(canvas.toDataURL("image/png"));
    }
    setImages(imagesList);
    setPageRendering(false);
  }

  useEffect(() => {
    if (item?.awsUrl?.length > 0) {
      const fetchFileFromUrl = async (url) => {
        const fileName = url;
        setfileType(fileName.includes(".pdf") ? "pdf" : "");
        if (fileName.includes(".pdf")) {
          loadPdf(item?.awsUrl[0]);
        }
      };
      fetchFileFromUrl(item.awsUrl[0]);
    }
  }, [item?.awsUrl]);

  useEffect(() => {
    renderPage();
  }, [pdf]);
  return (
    <Link to={`/home/PostAsViewByUser/${item.id}`}>
      <div className="flex items-center max-2xl:flex-col max-2xl:items-start max-2xl:gap-3">
        {item.postType !== "WRITE_UPS" && (
          <img
            src={fileType === "pdf" ? images[0] : item?.awsUrl[0]}
            alt="post"
            className="rounded-[10px] mr-4 min-w-[110px] max-w-[110px] h-[90px]"
          />
        )}
        <p
          className=" text-base font-normal"
          dangerouslySetInnerHTML={{
            __html:
              item.description?.length > 60 && item.postType !== "WRITE_UPS"
                ? item.description.slice(0, 60) + "..."
                : item.description?.length > 60 && item.postType == "WRITE_UPS"
                ? item.description.slice(0, 180) + "..."
                : item.description,
          }}></p>
      </div>
    </Link>
  );
}
