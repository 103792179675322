import React, { useEffect, useState } from "react";
import ProfileHeader from "./ProfileHeader";
import UserPost from "./UserPost";
import { UseCreatePostData } from "../ContextApis/CreatePostContext";
import { UseProfileContext } from "../ContextApis/ProfileContext";
import { useParams } from "react-router-dom";
const ProfileHome = () => {
  const { userPosts, getUserPost } = UseCreatePostData();
  const { profileData } = UseProfileContext();
  const [isBlock, setIsblock] = useState(false);
  const { username } = useParams();
  useEffect(() => {
    if (profileData?.userName !== null && username === undefined) {
      getUserPost(profileData?.userName);
    } else {
      getUserPost(username);
    }
  }, [profileData, username]);

  const parseDate = (dateString) => {
    const [datePart, timePart] = dateString.split(" | ");
    const [day, month, year] = datePart.split("-");
    const dateTimeString = `${year}-${month}-${day} ${timePart}`;
    return new Date(dateTimeString);
  };
  const sortedPosts = [...userPosts].sort(
    (a, b) => parseDate(b.postCreatedAt) - parseDate(a.postCreatedAt)
  );
  return (
    <>
     
      <ProfileHeader btn={"editBtn"} setIsblock={setIsblock} isBlock={isBlock} />
      <div className={`max-lg:px-[54px] max-sm:px-0 ${isBlock && "hidden"}`}>
        <div className="flex flex-col lg:max-w-[920px] mx-auto max-lg:w-full xl:mt-9 sm:mt-[50px] sm:border-t sm:border-r sm:border-l  border-1 rounded-t-2xl rounded-b-none xl:mb-20 sm:mb-15 mb-10">
          {Array.isArray(userPosts) &&
            userPosts.length > 0 &&
            sortedPosts.map((item, index) => {
              return <UserPost item={item} index={index} />;
            })}
        </div>
      </div>
    </>
  );
};
export default ProfileHome;
