/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import {
  LeftIcon,
  ThreeDots,
  MaxIcon,
  LikeIcon,
  DislikeIcon,
  ShareIcon,
  NextIcon,
  CloseIcon,
} from "../../components/common/Icons";
import Document from "../../assets/images/png/Document.png";
import { Link } from "react-router-dom";
import post_as_view_user_bg from "../../assets/images/png/post-view-user-profile-bg.png";
import post_as_view_user_pic from "../../assets/images/png/user-img2.png";
import post_as_view_user from "../../assets/images/png/user-img.png";
import books1 from "../../assets/images/png/books1.jpg";
import books2 from "../../assets/images/png/books2.jpg";
import books3 from "../../assets/images/png/books3.jpg";
const PostAsViewByReader = () => {
  const [showThreeDots, setShowThreeDots] = useState(false);
  const [report, setReport] = useState(false);
  const [like, setLike] = useState(false);
  const [disLike, setDisLike] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);
  const images = [Document, books1, books2, books3];
  function handlegallreyImgages(btnName) {
    let realIndex = imgIndex + 1;
    if (btnName === "next" && realIndex <= images.length - 1) {
      setImgIndex(imgIndex + 1);
    }
    if (btnName === "prev" && imgIndex >= 1) {
      setImgIndex(imgIndex - 1);
    }
  }
  const popupRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowThreeDots(false);
        setReport(false);
      }
    }

    if (showThreeDots || report) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showThreeDots, report]);
  return (
    <>
      {(showThreeDots || report) && (
        <div className="backdrop-blur-[10px] z-50 fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] h-[100vh] w-full"></div>
      )}
      {showThreeDots && (
        <div ref={popupRef} className="max-w-[185px] sm:max-w-[320px] bg-white rounded-[16px] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]  text-center w-full overflow-hidden z-[60] sm:h-auto sm:block flex sm:flex-row flex-col items-center sm:scroll-auto justify-center shadow">
          <a
            onClick={() => {
              setReport(true);
              setShowThreeDots(false);
            }}
            className="w-full font-normal sm:text-base text-sm text-red-500 py-2 inline-block cursor-pointer ">
            Report
          </a>
          <hr className="w-full" />
          <p  onClick={() => setShowThreeDots(false)} className="w-full font-normal sm:text-base text-sm py-2 cursor-pointer  inline-block">Copy link</p>
          <hr className="w-full" />
          <p onClick={() => setShowThreeDots(false)} className="w-full font-normal sm:text-base text-sm py-2  cursor-pointer inline-block">
            Not interested
          </p>
          <hr className="w-full" />
          <button
            className="w-full font-normal sm:text-base text-sm py-2 inline-block"
            onClick={() => setShowThreeDots(false)}>
            Cancel
          </button>
        </div>
      )}
      {report && (
        <div ref={popupRef} className="max-w-[320px] sm:max-w-[372px] bg-white rounded-[16px] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-center w-full overflow-hidden z-[60] sm:h-auto sm:block flex items-center sm:scroll-auto scroll-[0] sm:pt-[45px] shadow-[0_8px_20px_0_rgba(0,0,0,0.2)] xl:pb-10 sm:px-[18px] sm:pb-[45px] px-6 py-5 flex-col">
          <p className=" font-medium sm:text-base text-sm text-center ">Report Kathryn Murphy?</p>
          <p className="xl:text-base font-normal text-sm sm:my-6 my-4 text-center">
            Use this option to report inappropriate content or behaviour and prevent further
            interaction with this user. Your action helps maintain a safe environment for our
            community. Thank you for your contribution to our platform's integrity.
          </p>
          <p className="xl:text-base font-normal text-sm sm:mb-6 mb-4  text-center">
            Why are you reporting this account ?
          </p>
          <div className="flex justify-center">
            <div className="">
              <div className="flex items-center sm:mb-4 mb-[6px] ">
                <input type="radio" name="report" id="spam" />
                <label
                  htmlFor="spam"
                  className="sm:ms-[11px] ms-[14px] xl:text-base sm:text-sm text-xs font-normal">
                  Spam
                </label>
              </div>
              <div className="flex items-center sm:mb-4 mb-[6px]">
                <input type="radio" name="report" id="Offensive" />
                <label
                  htmlFor="Offensive"
                  className="sm:ms-[11px] ms-[14px] xl:text-base sm:text-sm text-xs font-normal">
                  Offensive contents
                </label>
              </div>
              <div className="flex items-center sm:mb-4 mb-[6px]">
                <input type="radio" name="report" id="Fake" />
                <label
                  htmlFor="Fake"
                  className="sm:ms-[11px] ms-[14px] xl:text-base sm:text-sm text-xs font-normal">
                  Fake account
                </label>
              </div>
              <div className="flex items-center sm:mb-6 mb-[6px]">
                <input type="radio" name="report" id="Other" />
                <label
                  htmlFor="Other"
                  className="sm:ms-[11px] ms-[14px] xl:text-base sm:text-sm text-xs font-normal">
                  Other
                </label>
              </div>
            </div>
          </div>
          <input
            type="text"
            placeholder="reason"
            className="bg-transparent border-b border-[#EEEFEF] w-[187px] max-sm:text-xs"
          />
          <div className="flex justify-center mt-6">
            <button className="inline-block me-[25px]">
              <div className="">
                <button
                  onClick={() => {
                    setReport(false);
                  }}
                  className=" text-white font-normal lg:text-base text-sm h-[40px] md:h-[50px] lg:h-[60px] w-[106px] md:w-[132px] flex items-center justify-center lg:px-10 bg-primary rounded-[100px] mx-auto sm:px-7 px-6">
                  Report
                </button>
              </div>
            </button>
            <button className="inline-block">
              <div className="h-[40px]  justify-center items-center bg-white flex rounded-[100px] ">
                <button
                  className=" text-black font-normal lg:text-base text-sm h-[40px] md:h-[50px] lg:h-[60px] w-[106px] md:w-[132px] flex items-center justify-center lg:px-10 bg-white rounded-[100px] mx-auto sm:px-7 px-6 border-grey border"
                  onClick={() => {
                    setReport(false);
                  }}>
                  Cancel
                </button>
              </div>
            </button>
          </div>
          <div
            className="absolute right-[20px] top-[15px] cursor-pointer"
            onClick={() => setReport(false)}>
            <CloseIcon />
          </div>
        </div>
      )}
      <div className=" max-w-[1800px] mx-auto sm:px-6 px-4 lg:pt-[26px] sm:pt-[31px] pt-5 flex pb-5 max-lg:flex-col">
        <div className=" w-3/4 max-lg:w-full">
          <div className=" flex justify-between pb-4">
            <Link onClick={() => window.history.back()}>
              <LeftIcon />
            </Link>

          </div>
          <div className="lg:px-15">
            <div className="flex justify-between">
              <Link to="/profile">
              <div className="flex items-center pb-4">
                <div className="sm:pr-[10px] pr-[14px]">
                  <img src={post_as_view_user} alt="post_as_view_user" />
                </div>
                <div>
                  <p className="sm:font-bold font-medium sm:text-base text-sm">Aadya Rathore</p>
                  <p className="text-normal sm:text-base text-sm ">
                    HOD of Physics{" "}
                    <span className="text-grey font-normal sm:text-base text-xs pl-2">22h</span>
                  </p>
                </div>
              </div></Link>
              <div className="relative">
                <button
                  onClick={(e) => {
                    {
                      e.preventDefault();
                      setShowThreeDots(!showThreeDots);
                    }
                  }}>
                  <ThreeDots />
                </button>
              </div>
            </div>
            <p className="lg:pb-0 sm:pb-6 pb-0 sm:mb-9 mb-[9px] text-sm sm:text-base md:text-md">
              We cover everything you need to build your first website. From creating your first
              page through to uploading your website to the internet. We’ll use the world’s most
              popular (and free) web design tool called Visual Studio Code. There are exercise files
              you can download and then work along with me. At the end of each video I have a
              downloadable version of where we are in the process so that you can compare your
              project with mine. This will enable you to see easily where you might have a problem.
              We will delve into all the good stuff such as how to create your very own mobile
              burger menu from scratch learning some basic JavaScript and jQuery. If that all sounds
              a little too fancy - don’t worry, this course is aimed at people new to web design and
              who have never coded before. We’ll start right at the beginning and work our way
              through step by step. In ut aliquet ante. Curabitur mollis tincidunt turpis, sed
              aliquam mauris finibus vel. Praesent eget mi in mi maximus egestas. Mauris eget ipsum
              in justo bibendum pellentesque. Sed id arcu in arcu ullamcorper eleifend condimentum
              quis diam. Phasellus tempus, urna ut auctor mattis, nisi nunc tincidunt lorem, eu
              egestas augue lectus sit amet sapien. Maecenas tristique aliquet massa, a venenatis
              augue tempor in. Aliquam turpis urna, imperdiet in lacus a, posuere suscipit augue.
              Nullam non quam a lectus finibus varius nec a orci. Aliquam efficitur sem cursus elit
              efficitur lacinia Morbi sit amet pretium tellus. Donec blandit fermentum tincidunt.
              Proin iaculis sem et imperdiet tristique. Nam varius ac nisl id sodales. Donec iaculis
              interdum mattis. Curabitur posuere ultricies diam in egestas. Donec id diam et lacus
              pharetra vestibulum a id est. Mauris vestibulum massa quis elit feugiat, dictum
              maximus ipsum pellentesque. Sed elementum, libero id lacinia aliquet, purus nibh
              consectetur mauris, eget interdum mi lacus vitae sem. Donec congue aliquam lorem nec
              congue. Suspendisse eu risus mattis, interdum ante sed, fringilla urna. Praesent
              mattis dictum sapien a lacinia. Ut scelerisque magna.
            </p>
            <div className="rounded-[16px] sm:rounded-[31px] bg-[#F8F8F8] lg:py-6 sm:py-7 py-3 overflow-hidden relative">
              <button
                onClick={() => handlegallreyImgages("next")}
                className="bg-white flex items-center justify-center sm:w-[37px] w-[19px] sm:h-[37px] h-[19px] rounded-[50%] absolute top-1/2 right-[26px] sm:right-[38px] md:right-[59px] -translate-y-1/2 slider_btn">
                <NextIcon />
              </button>
              <button
                onClick={() => handlegallreyImgages("prev")}
                className="bg-white flex items-center justify-center sm:w-[37px] w-[19px] sm:h-[37px] h-[19px] rounded-[50%] absolute top-1/2 left-[26px] sm:left-[38px] md:left-[59px] -translate-y-1/2 rotate-180 slider_btn">
                <NextIcon />
              </button>
              <div className=" sm:px-12 px-7 max-sm:py-3 max-sm:px-7 flex justify-between sm:pb-7 pb-3">
                <p className="font-semibold sm:text-md xl:text-lg text-sm">Title of the document</p>
                <p className="font-semibold sm:text-md xl:text-lg text-sm">Notes</p>
              </div>
              <div className="w-full flex justify-center h-[200px] sm:h-[300px] md:h-[400px] lg:h-[500px] overflow-hidden text-center xxl:h-[600px]">
                <img  src={images[imgIndex]} alt=""  />
              </div>
              <div className=" sm:px-12 px-7 max-sm:py-3 max-sm:px-7 flex justify-between sm:pt-7 pt-3">
                <p className="font-semibold sm:text-md xl:text-lg text-xs">
                  {imgIndex + 1}/{images.length}{" "}
                  <button className="ms-6 text-primary">
                    {imgIndex + 1 === images.length && "Open"}
                  </button>
                </p>
                <Link  to="/showpdf"  target="_blank" className="max_icon">
                  <MaxIcon />
                </Link>
              </div>
            </div>
            <div className="flex items-center sm:pt-8 pt-[9px]">
              <div className="sm:mr-12 mr-6 flex items-center">
                <span
                  onClick={() => {
                    setDisLike(false);
                    setLike(true);
                  }}
                  className={`inline-block sm:pr-[5px] pr-[3px] like_icon cursor-pointer ${like ? "like" : ""
                    }`}>
                  <LikeIcon />
                </span>
                <span className="lg:text-xxl sm:text-lg text-base font-normal ms-1">
                  {like ? "1" : "0"}
                </span>
              </div>
              <div className="sm:mr-12 mr-6 flex items-center">
                <span onClick={() => {
                  setDisLike(true);
                  setLike(false);
                }} className={`  like_icon pr-[3px] cursor-pointer ${disLike ? "like" : ""}`}>
                  <DislikeIcon />
                </span>
                {/* <span className="lg:text-xxl sm:text-lg text-base font-normal ms-1">
                  {disLike ? "1" : "0"}
                </span> */}
              </div>
              <div className="share_icon cursor-pointer">
                <ShareIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/4 mt-[92px] max-lg:w-full lg:block hidden lg:ps-7 ">
        <Link to="/profile">
          <div className="border-2 border-[#EEEFEF] rounded-[11px] mb-6">
            <div className="relative max-xl:max-w-[270px] max-xl:mx-auto">
              <img
                className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[114px] h-[114px]"
                src={post_as_view_user_pic}
                alt="post_as_view_user_pic"
              />
              <img className="w-full" src={post_as_view_user_bg} alt="post_as_view_user_bg" />
            </div>
            <div className="text-center xl:pt-[33px] xl:pb-[29px]">
              <p className="pb-6 text-xl font-semibold ">Sandeep Bansal</p>
              <p className="pb-6 font-normal fs-base">Experience designer</p>
              <p className=" font-semibold text-base">1000 Followers</p>
            </div>
          </div>
          </Link>
        </div>
      </div>
    </>
  );
};
export default PostAsViewByReader;
