import React, { useState, useEffect } from "react";
import { CrossIcon, ExamIcon, PostsIcon, SearchIcon2 } from "../common/Icons";
import { Link } from "react-router-dom";
import { UseNavDataContext } from "../ContextApis/NavDataContext";
import userImg from "../../assets/images/png/user-img2.png";
import DOMPurify from "dompurify";
import { UseQuestionPaperContext } from "../ContextApis/QuestionPaper";

const Search = ({ closeMenu }) => {
  const [search, setSearch] = useState("");
  const [showGlobalDropdown, setShowGlobalDropdown] = useState(false);
  const { global, getGlobal } = UseNavDataContext();
  const { getAllExam, AllExam } = UseQuestionPaperContext();
  const [recentSearchUser, setRecentSearchUser] = useState(
    JSON.parse(localStorage.getItem("recentSeach"))
  );

  const [usersExpand, setUsersExpand] = useState(false);
  const [postExpand, setPostExpand] = useState(false);
  const [examExpand, setExamExpand] = useState(false);

  const filterRecentData = Array.isArray(recentSearchUser)
    ? recentSearchUser.filter(
        (item) =>
          typeof item && item.fullName && item.fullName.toLowerCase().includes(search.toLowerCase())
      )
    : [];

  useEffect(() => {
    if (search.length > 0) {
      getGlobal(search);
      setExamExpand(false);
      setUsersExpand(false);
      setPostExpand(false);
    } else {
      setExamExpand(false);
      setUsersExpand(false);
      setPostExpand(false);
      getGlobal("");
    }
    setRecentSearchUser(JSON.parse(localStorage.getItem("recentSearch")));
  }, [search]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    if (value.length > 0) {
      setShowGlobalDropdown(true);
    } else {
      setShowGlobalDropdown(false);
    }
  };
  function handleStoreSearch(items) {
    let recentSearch = JSON.parse(localStorage.getItem("recentSearch"));
    let getlocal = Array.isArray(recentSearch) ? recentSearch : [];
    if (
      recentSearch &&
      recentSearch.length >= 3 &&
      recentSearch
        .map((item) => item.userName !== items.userName)
        .every((itemss) => itemss === true)
    ) {
      getlocal.shift();
      let seachArray = [
        ...getlocal,
        {
          fullName: items.fullName,
          userName: items.userName,
          profileImageUrl: items.profileImageUrl,
        },
      ];
      localStorage.setItem("recentSearch", JSON.stringify(seachArray));
    } else if (
      recentSearch &&
      recentSearch
        .map((item) => item.userName !== items.userName)
        .every((itemss) => itemss === true)
    ) {
      let seachArray = [
        ...getlocal,
        {
          fullName: items.fullName,
          userName: items.userName,
          profileImageUrl: items.profileImageUrl,
        },
      ];
      localStorage.setItem("recentSearch", JSON.stringify(seachArray));
    } else if (!recentSearch) {
      let seachArray = [
        {
          fullName: items.fullName,
          userName: items.userName,
          profileImageUrl: items.profileImageUrl,
        },
      ];
      localStorage.setItem("recentSearch", JSON.stringify(seachArray));
    }
  }

  let usersData =
    !usersExpand && global?.users && global?.users.length > 4
      ? global?.users.slice(0, 4)
      : global.users || [];

  let postData =
    !postExpand && global?.posts && global?.posts.length > 4
      ? global?.posts.slice(0, 4)
      : global.posts || [];

  let examsData = global?.exams || [];
  let exams = [];
  if (examsData) {
    for (let i in examsData) {
      for (let a of examsData[i]) {
        exams.push(a);
      }
    }
  }
  return (
    <div className="sm:max-w-[586px] sm:min-h-[500px] max-sm:h-full w-full bg-white pt-[34px] pb-[25px] ps-[15px] pe-0 sm:px-[33px] sm:rounded-[16px] md:rounded-[40px] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[60] flex flex-col items-start shadow-[0_8px_20px_0_rgba(0,0,0,0.2)]">
      <div className="flex items-center justify-between w-full max-sm:pe-[15px]">
        <div className="flex items-center bg-light-400 justify-between rounded-[32px] px-6 h-[40px] w-full">
          <input
            type="text"
            index="input"
            autoFocus
            onChange={handleSearchChange}
            placeholder="Search"
            value={search}
            className="text-base focus:outline-none w-full bg-transparent"
            onFocus={() => setShowGlobalDropdown(search.length > 0)}
            onBlur={() => setTimeout(() => setShowGlobalDropdown(false), 200)}
          />
          <label htmlFor="input">
            <SearchIcon2 />
          </label>
        </div>
        <button onClick={() => closeMenu(false)} className="ms-3 -mr-3 md:hidden">
          <CrossIcon />
        </button>
      </div>
      <div className="h-full sm:h-[400px] overflow-y-auto w-full mt-4 pe-[15px]">
        <div>
          {Array.isArray(recentSearchUser) &&
            recentSearchUser.length > 0 &&
            filterRecentData.length > 0 && (
              <div className="flex items-center justify-between mb-3">
                <p className="font-semibold text-base">Recent</p>
                <button
                  onClick={() => {
                    localStorage.removeItem("recentSearch");
                    setRecentSearchUser([]);
                  }}
                  className="text-base">
                  Clear
                </button>
              </div>
            )}
          {filterRecentData.length > 0 &&
            filterRecentData.slice(0, 3).map((item, i) => (
              <Link
                to={`/ViewByUserprofile/${item.userName}`}
                key={i}
                className="flex items-center gap-5 py-1.5 border-b border-1 border-[#00000019] last:border-0 ">
                <p
                  className="text-tertiary cursor-pointer"
                  onClick={() => {
                    setSearch(item.fullName);
                    closeMenu(false);
                  }}>
                  {item.fullName}
                </p>
                <img
                  className="w-[30px] h-[30px] rounded-full"
                  src={item.profileImageUrl || userImg}
                  alt=""
                />
              </Link>
            ))}
        </div>
        <div className="mt-4">
          {usersData.length > 0 ? (
            <>
              <p className="font-semibold text-base mb-3">Users</p>
              {usersData.map((item, i) => (
                <Link
                  onClick={() => handleStoreSearch(item)}
                  to={`/ViewByUserprofile/${item.userName}`}
                  key={i}
                  className="flex items-center gap-5 py-1.5 border-b border-1 border-[#00000016] last:border-0 ">
                  <p
                    className="text-tertiary cursor-pointer"
                    onClick={() => {
                      setSearch(item.fullName);
                      closeMenu(false);
                    }}>
                    {item.fullName}
                  </p>
                  <img
                    className="w-[30px] h-[30px] rounded-full"
                    src={item.profileImageUrl || userImg}
                    alt=""
                  />
                </Link>
              ))}
            </>
          ) : (
            ""
          )}
          {!usersExpand && usersData.length >= 4 && (
            <div className="text-center mt-2">
              <button onClick={() => setUsersExpand(!usersExpand)} className="text-primary">
                See more users
              </button>
            </div>
          )}
        </div>
        <div className="mt-4">
          {postData.length > 0 ? (
            <>
              <p className="font-semibold text-base mb-3">Posts</p>
              {postData.map((item, i) => {
                let des = DOMPurify.sanitize(item?.description);
                return (
                  <Link
                    onClick={() => handleStoreSearch(item)}
                    to={`/home/PostAsViewByUser/${item.id}`}
                    key={i}
                    className="flex items-center gap-5 py-1.5 border-b border-1 border-[#00000019] last:border-0 ">
                    <p
                      className="text-tertiary cursor-pointer"
                      onClick={() => {
                        setSearch(item.fullName);
                        closeMenu(false);
                      }}
                      dangerouslySetInnerHTML={{
                        __html: des.length > 30 ? des.slice(0, 30) + "..." : des,
                      }}></p>
                  </Link>
                );
              })}
            </>
          ) : (
            ""
          )}
          {!postExpand && postData.length >= 4 && (
            <div className="text-center mt-2">
              <button onClick={() => setPostExpand(!postExpand)} className="text-primary">
                See more posts
              </button>
            </div>
          )}
        </div>
        <div className="mt-4">
          {exams.length > 0 ? (
            <>
              <p className="font-semibold text-base mb-3">Exams</p>
              {(!examExpand && exams.length > 4 ? exams.slice(0, 4) : exams).map((item, i) => (
                <Link
                  onClick={() => handleStoreSearch(item)}
                  to={`/streamhome/${item}`}
                  key={i}
                  className="flex items-center gap-5 py-1.5 border-b border-1 border-[#00000019] last:border-0 ">
                  <p
                    className="text-tertiary cursor-pointer"
                    onClick={() => {
                      setSearch(item);

                      closeMenu(false);
                    }}>
                    {console.log(item.length > 35 ? item.slice(0, 35) + "..." : item)}
                    {item.length > 35 ? item.slice(0, 35) + "..." : item}
                  </p>
                </Link>
              ))}
            </>
          ) : (
            ""
          )}
          {!examExpand && exams.length >= 4 && (
            <div className="text-center mt-2">
              <button onClick={() => setExamExpand(!examExpand)} className="text-primary">
                See more Exams
              </button>
            </div>
          )}
        </div>
      </div>
      {(!global.users || global.users.length === 0) &&
        (!global.exams ||
          (typeof global.exams === "object" && Object.keys(global.exams).length === 0)) &&
        (!global.posts || global.posts.length === 0) &&
        filterRecentData.length === 0 && (
          <p className="text-gray-500 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            No results found
          </p>
        )}
    </div>
  );
};

export default Search;
