import React from "react";
import DOMPurify from "dompurify";
import { Link, NavLink } from "react-router-dom";
import { toast } from "react-toastify";

const AllNotifications = ({
  notif,
  handleNotificationClick,
  timeAgo,
  live_dott,
  bansal_sndp,
  goback,
}) => {
  // Sanitize the notification message
  const sanitizedHtml = DOMPurify.sanitize(notif.message);

  return (
    <Link
      to={notif.redirectUrl !== null && notif.redirectUrl}
      key={notif.id}
      onClick={() => {
        handleNotificationClick(notif.id);
        if (notif.redirectUrl) {
          goback();
        } else {
          toast.error("Post has been deleted by user !");
        }
      }}
      className={`notification-item flex justify-between items-start mb-6 w-full ${
        notif.notificationStatus === "UNREAD" ? "bg-light-gray" : ""
      }`}>
      <div className="flex items-start">
        <img
          className="min-w-[36px] max-w-[36px] h-[36px] sm:min-w-[45px] sm:max-w-[45px] sm:h-[45px] rounded-full"
          src={bansal_sndp}
          alt="Profile"
        />
        <div className="ms-2.5 descri">
          <div className="text-sm sm:text-md" dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
          <p className="text-grey text-xs sm:text-base mt-0.5">{timeAgo(notif.createdAt)}</p>
        </div>
      </div>
      {notif.notificationStatus === "UNREAD" && (
        <img className="cursor-pointer mt-4 ms-6" src={live_dott} alt="Unread Indicator" />
      )}
    </Link>
  );
};

export default AllNotifications;
